import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchDirectory from "../components/SearchDirectory"

const DirectoriesPage = (props) => {
  // isAuthenticated() && navigate('/u/dashboard')

  return (
    <Layout>
      <SEO title="CCO Business Directory" />
      <SearchDirectory {...props} />
    </Layout>
  )
}

export default DirectoriesPage
